import BankAccountService from "@/service/BankAccountService";
import {ref} from "vue";

export function useBankAccountList() {
    const banks = ref([]);
    const bankAccounts = ref([]);
    const totalBankAccounts = ref(0);
    const bankAccountService = new BankAccountService();

    const getBankAccounts = async (query) => {
        const res = await bankAccountService.getAll(query)
        const data = res.data
        bankAccounts.value = data.bank_accounts
        totalBankAccounts.value = data.total
    }

    const getBanks = async () => {
        const res = await bankAccountService.getBanks()
        const data = res.data

        const tempBank = {}
        data?.forEach(bank => {
            tempBank[bank.id] = bank.name
        })
        banks.value = tempBank
    }

    const deleteBank = async (id) => {
        await bankAccountService.delete(id)
    }

    return {banks, bankAccounts, totalBankAccounts, bankAccountService, getBankAccounts, deleteBank, getBanks}
}


export function useBankAccountForm() {
    const bankAccountService = new BankAccountService();

    const create = async (data) => {
        await bankAccountService.create(data)
    }

    const update = async (id, data) => {
        await bankAccountService.update(id, data)
    }

    return {create, update}
}