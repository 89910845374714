<template>
  <div>
  <Dialog v-model:visible="showDialog" :style="{ width: '450px' }" header="Akun Bank" :modal="true">
    <Form :initial-value="initialData" :salon-name="props.salonName" @submit="submitBankAccount" />
  </Dialog>

  <div class="tw-bg-white tw-p-5 md:tw-px-10">
    <Table :rows="rows" 
      :columns="columns" 
      :data="bankAccounts.map((bank, index) => ({ ...bank, index: index + 1 }))"
      :total-items="totalBankAccounts" 
      main-url="bank-accounts" 
      @on-page-change="onPageChange">

      <template #header >
        <div class="tw-bg-white tw-border-none">
          <div class="tw-flex tw-items-center tw-justify-between">
            <h1 class="tw-text-2xl">Akun Bank</h1>
            <Button label="Tambah" 
              icon="pi pi-plus" 
              class="p-button-sm tw-bg-blue-light tw-border-blue-light tw-h-10"
              @click="createBankAccount" />
          </div>
        </div>
      </template>

      <template #column>
        <Column header="Aksi">
          <template #body="context">
            <a :href="`${context.data.id}`" class="tw-mr-4" @click.prevent="editBankAccount(context.data)">
              <i class="pi pi-pencil tw-text-lg"></i>
            </a>
            <a :href="`delete/${context.data.id}`" @click.prevent="deleteRecord(context.data.id)">
              <i class="pi pi-trash"></i>
            </a>
          </template>
        </Column>
      </template>
    </Table>
  </div>
</div>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue';

import Table from './../components/Table';
import {
  useBankAccountForm,
  useBankAccountList,
} from '@/composables/bank_accounts';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import Form from './Form';
import { reset } from '@formkit/vue';
import { useAuthRole } from '@/composables/auth';

const props = defineProps({
  salonName: String,
});
const rows = ref(10);
const showDialog = ref(false);
const isEdit = ref(false);

const confirm = useConfirm();
const toast = useToast();
const route = useRoute();
const role = useAuthRole();
const { bankAccounts, totalBankAccounts, getBankAccounts, deleteBank } =
  useBankAccountList();
const { create, update } = useBankAccountForm();
const initialData = ref({
  salon_id: route.params.id,
});

const columns = [
  { field: 'index', header: 'No', sortable: true, },
  { field: 'bank_id', header: 'ID Bank', sortable: true, },
  { field: 'bank_name', header: 'Nama Bank', sortable: true, },
  { field: 'account_no', header: 'Nomor Rekening', sortable: true, },
  { field: 'name', header: 'Atas Nama', sortable: true, },
  { field: 'is_publish', header: 'Apakah dipublish', sortable: true, },
];

const createBankAccount = () => {
  initialData.value = { salon_id: route.params.id || role.salonId.value };
  showDialog.value = true;
  isEdit.value = false;
};

const onPageChange = ({ rows, first }) => {
  getBankAccounts({
    limit: rows,
    offset: first,
    salon_id: route.params.id || role.salonId.value,
  });
};

const deleteRecord = (id) => {
  confirm.require({
    message: 'Apakah Anda akan menghapus akun Bank ini?',
    header: 'Konfirmasi',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger',
    accept: async () => {
      await deleteBank(id);
      await getBankAccounts({
        limit: rows.value,
        salon_id: route.params.id || role.salonId.value,
      });
      toast.add({
        severity: 'success',
        summary: 'Confirmed',
        detail: 'Record deleted',
        life: 3000,
      });
    },
  });
};

const editBankAccount = (data) => {
  isEdit.value = true;
  showDialog.value = true;
  initialData.value = { ...data };
};

const submitBankAccount = async (data) => {
  if (isEdit.value) await update(initialData.value?.id, data);
  else await create(data);
  await getBankAccounts({
    limit: rows.value,
    salon_id: route.params.id || role.salonId.value,
  });
  showDialog.value = false;
  reset('bank-accounts');
  isEdit.value = false;
};

onMounted(async () => {
  await getBankAccounts({
    limit: rows.value,
    salon_id: route.params.id || role.salonId.value,
  });
});
</script>

<style scoped>
:deep(.p-datatable-header) {
  background: #fff;
  color: #343a40;
  padding: 1rem 0;
  font-weight: 700;
  border: none;
}

:deep(.p-datatable-thead > tr > th) {
  border: none;
}

:deep(.p-paginator) {
  @apply tw-rounded-b-md tw-rounded-t-none tw-border-0 tw-px-6 tw-p-4 tw-pt-0
}
</style>
