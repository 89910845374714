<template>
  <FormKit
      type="form"
      id="bank-accounts"
      :actions="false"
      :value="initialValue"
      :classes="{messages: 'tw-text-red-500 tw-text-center tw-mt-4'}"
      @submit="submit"
  >
    <div class="tw-mb-4">
      <h1>Salon</h1>
      <div>{{props.salonName}}</div>
    </div>
    <div v-if="props.initialValue?.bank_name" class="tw-mb-4">
      <h1>Bank</h1>
      <div>{{props.initialValue.bank_name}}</div>
    </div>
    <FormKit
        v-else
        name="bank_id"
        type="select"
        label="Bank"
        validation="required"
        placeholder="Pilih Bank"
        :classes="defaultFormikListClass"
        :options="banks"
    >
      <template #input="context">
        <div class="p-fluid">
          <Dropdown v-model="context.value" @change="(e) => {context.node.input(e.value)}" :options="context.options" optionLabel="label" optionValue="value" :placeholder="context.placeholder">
            <template #option="optionContext">
              <option v-if="!optionContext.option.attrs" :key="optionContext.index" :value="optionContext.option.value">{{optionContext.option.label}}</option>
              <option v-else :key="optionContext.index" :disabled="true">Not Selected</option>
            </template>
          </Dropdown>
        </div>
      </template>
    </FormKit>
    <FormKit
        name="name"
        type="text"
        label="Nama"
        validation="required"
        validation-visibility="dirty"
        :classes="defaultFormikListClass"
    />
    <FormKit
        name="account_no"
        type="text"
        label="Nomor Akun Bank"
        validation="required|number"
        validation-visibility="dirty"
        :classes="defaultFormikListClass"
    />
    <FormKit
        name="is_publish"
        type="checkbox"
        label="Apakah dipublish"
        :classes="{wrapper: 'tw-flex', label: 'tw-ml-4', outer: 'tw-my-4'}"
    />
  </FormKit>
  <div class="p-fluid tw-mt-12 tw-mb-4">
    <Button label="Submit" @click="submitData" />
  </div>
</template>

<script setup>
import {defineEmits, defineProps, onBeforeMount} from "vue";
import {submitForm} from "@formkit/vue";
import {defaultFormikListClass} from "@/constants/formik";
import {useBankAccountList} from "@/composables/bank_accounts";

const props = defineProps({
  initialValue: {
    type: Object,
    required: false
  },
  salonName: String,
})
const emit = defineEmits(['submit'])

const {banks, getBanks} = useBankAccountList()

const submit = (data) => {
  const formData = {
    account_no: data.account_no,
    bank_id: parseInt(data.bank_id),
    salon_id: parseInt(data.salon_id),
    name: data.name,
    is_publish: data.is_publish || false,
  }
  emit('submit', formData)
}

const submitData = () => {
  submitForm('bank-accounts')
}

onBeforeMount(async () => {
  await getBanks()
})
</script>
