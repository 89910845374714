import axios from "axios";

const BANK_ACCOUNT_URL = 'api/v1/bankaccounts'

export default class BankAccountService {
    getAll(queryParam) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${BANK_ACCOUNT_URL}/list`, {params: queryParam}).then(res => res.data)
    }

    getBanks() {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${BANK_ACCOUNT_URL}/ddl`, ).then(res => res.data)
    }

    edit(id, data) {
        return axios.put(`${process.env.VUE_APP_PRITI_MAIN_API}/${BANK_ACCOUNT_URL}/${id}`, data)
    }

    create(data) {
        return axios.post(`${process.env.VUE_APP_PRITI_MAIN_API}/${BANK_ACCOUNT_URL}`, data).then(res => {
            res.data
        })
    }

    update(id, data) {
        return axios.put(`${process.env.VUE_APP_PRITI_MAIN_API}/${BANK_ACCOUNT_URL}/${id}`, data).then(res => {
            res.data
        })
    }

    delete(id) {
        return axios.delete(`${process.env.VUE_APP_PRITI_MAIN_API}/${BANK_ACCOUNT_URL}/${id}`).then(res => res.data);
    }
}