export const defaultFormikClass = {
    outer: 'tw-mt-1 sm:tw-mt-2 tw-w-full',
    label: 'tw-text-lg',
    inner: 'tw-py-1 sm:tw-pt-2 tw-overflow-hidden',
    input: 'tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded',
    help: 'tw-text-sm tw-text-gray-400',
    messages: 'tw-text-red-500'
}

export const defaultFormikListClass = {
    outer: 'tw-my-2 tw-w-full',
    label: 'tw-text-base',
    inner: 'tw-py-2 tw-overflow-hidden',
    input: 'tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded',
    help: 'tw-text-sm tw-text-gray-400',
    messages: 'tw-text-red-500'
}
