import axios from "axios";

const SALON_URL = "api/v1/salons";

export default class SalonService {
  registerSalon(data) {
    return axios
      .post(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_URL}/register`, data)
      .then((res) => res)
      .catch((e) => e.response);
  }

  createSalon(data) {
    return axios.post(
      `${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_URL}/`,
      data
    );
  }

  updateSalon(id, data) {
    return axios.put(
      `${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_URL}/${id}`,
      data
    );
  }

  getAll(queryParam) {
    return axios
      .get(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_URL}/`, {
        params: queryParam,
      })
      .then((res) => res.data);
  }

  getOne(id) {
    return axios
      .get(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_URL}/${id}`)
      .then((res) => res.data);
  }

  getDropdown({ queryParam = {} }) {
    return axios
      .get(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_URL}/ddl`, {
        params: queryParam,
      })
      .then((res) => res.data);
  }

  resetStatusSalon(id) {
    return axios
      .put(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_URL}/${id}/status`, {
        reason: " ",
        status: 0,
      })
      .then((res) => res)
      .catch((e) => e.response);
  }

  approveSalon(id) {
    return axios
      .put(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_URL}/${id}/status`, {
        reason: " ",
        status: 1,
      })
      .then((res) => res)
      .catch((e) => e.response);
  }

  denySalon(id, reason) {
    return axios
      .put(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_URL}/${id}/status`, {
        reason: reason,
        status: 2,
      })
      .then((res) => res)
      .catch((e) => e.response);
  }

  activeInactiveSalon(id, status) {
    return axios
      .put(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_URL}/${id}/status`, {
        reason: " ",
        status: status,
      })
      .then((res) => res)
      .catch((e) => e.response);
  }

  uploadMainBanner(id, formData) {
    return axios.post(
      `${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_URL}/${id}/upload/main-banner`,
      formData
    );
  }

  getBanners(id) {
    return axios
      .get(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_URL}/banners`, {
        params: { salons_id: id },
      })
      .then((res) => res.data);
  }

  deleteBanner(salonId, bannerId) {
    return axios.delete(
      `${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_URL}/${salonId}/banners/${bannerId}`
    );
  }

  uploadBanners(id, formData) {
    return axios.post(
      `${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_URL}/${id}/upload/banners`,
      formData
    );
  }

  getOperationalSchedules(salonID) {
    return axios
      .get(
        `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/salons/${salonID}/schedules`
      )
      .then((res) => res.data);
  }

  postOperationalSchedules(data) {
    return axios
      .post(
        `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/salons/schedules`,
        data
      )
      .then((res) => res.data)
      .catch((e) => e.response);
  }

  putOperationalSchedules(salonID, scheduleID, data) {
    return axios
      .put(
        `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/salons/${salonID}/schedules/${scheduleID}`,
        data
      )
      .then((res) => res.data)
      .catch((e) => e.response);
  }
}
