<template>
  <div v-if="props.context.src === null">
    <FileUpload
      v-if="!blobUrl"
      mode="basic"
      :custom-upload="true"
      name="image"
      accept="image/*"
      :max-file-size="10000000"
      @select="fileSelected"
    />
    <div v-else>
      <div class="tw-flex tw-justify-end tw-mb-5">
        <Button
          icon="pi pi-trash"
          class="p-button-rounded p-button-danger"
          @click="removeMedia"
        />
      </div>
      <Image :src="blobUrl" />
    </div>
  </div>

  <div v-else>
    <div v-if="!editMode">
      <div class="tw-flex tw-justify-start">
        <Button iconPos="left" icon="pi pi-pencil" @click="toggleEdit" />
      </div>
      <Image :src="props.context.src" />
    </div>

    <div v-else>
      <div class="input-file-wrapper">
        <div class="input-file" v-if="!blobUrl">
          <Button
            type="button"
            iconPos="left"
            icon="pi pi-cloud-upload"
            class="tw-h-9 tw-text-sm tw-mb-3 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300"
          />
          <p>
            <span class="inter-bold tw-text-blue-light"
              >Klik untuk unggah atau seret kesini</span
            >
          </p>
          <p class="inter-bold tw-text-blue-light">
            JPG, JPEG, PNG (maks. 2MB)
          </p>
          <input
            type="file"
            accept=".png, .jpeg, .jpg"
            @change="fileSelected"
          />
        </div>
        <div class="preview tw-p-6" v-else>
          <Image v-if="!loading && blobUrl" :src="blobUrl" alt="Preview" />
          <div class="delete" @click="updateImage(false)">
            <i class="pi pi-trash tw-text-white"></i>
          </div>
        </div>
      </div>
      <Button
        :disabled="!blobUrl"
        icon="pi pi-check"
        class="p-button-rounded p-button-success"
        @click="updateImage(true)"
      />
      <Button
        icon="pi pi-times"
        class="p-button-rounded p-button-danger"
        @click="updateImage(false)"
      />
      <br />
      <div v-if="loading" class="tw-flex tw-justify-center">
        <ProgressSpinner stroke-width="5" animation-duration="2s" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";

import { useMediaUtils } from "@/composables/utils";

const editMode = ref(false);
const loading = ref(false);
const props = defineProps({
  context: Object,
});

const { uploadCustomFileUpload, removeMedia, blobUrl, media, mediaSelected } =
  useMediaUtils();

const toggleEdit = () => {
  editMode.value = !editMode.value;
};

const updateImage = async (isApprove = false) => {
  if (isApprove) {
    loading.value = true;
    props.context.node.input(media.value);
    await props.context.upload(media.value);
  }
  loading.value = false;
  toggleEdit();
  removeMedia();
};
const fileSelected = (event) => {
  uploadCustomFileUpload(event);

  if (mediaSelected.value) {
    props.context.node.input(media.value);
  } else {
    props.context.node.input(null);
  }
};
</script>
