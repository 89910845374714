<template>
  <div>
    <Dialog header="Tambah Banner Baru" v-model:visible="displayCreateModal"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '40vw' }" :modal="true">
      <FormKit v-model="data" :type="uploadImage" name="image" validation="required" validation-visibility="dirty"
        :classes="defaultFormikClass" />
      <template #footer>
        <div class="p-fluid tw-mt-10 tw-mb-2">
          <Button label="Submit" @click="submitBanner" :disabled="!data" />
        </div>
      </template>
    </Dialog>
  </div>

  <div class="mb-5 tw-p-5 md:tw-px-10 tw-bg-white">
    <div class="tw-flex tw-items-center tw-justify-between tw-mb-3">
      <h1 class="tw-font-bold tw-text-2xl">Daftar Banner</h1>
      <div class="tw-flex tw-items-center tw-gap-2">
        <Button label="Tambah" 
          icon="pi pi-plus" 
          class="p-button-sm tw-bg-blue-light tw-border-blue-light tw-h-10"
          @click="toggleCreateModal" 
        />
      </div>
    </div>

    <div class="grid">
      <div class="col-12 md:col-6 lg:col-4" v-for="banner, index of banners" :key="index">
        <div class="card tw-p-0 tw-overflow-hidden">
          <img :src="banner.image_url" style="height: 262px;" class="tw-w-full tw-object-cover" />
          <div class="tw-bg-white tw-p-5 tw-flex tw-items-center tw-justify-between">
            <p class="tw-font-bold tw-text-lg">
              Banner {{ index + 1 }}
            </p>
            <Button label="Hapus"
              outlined 
              icon="pi pi-trash" 
              class="p-button-danger tw-py-1.5" 
              @click="() => showDeleteConfirmation(index)" 
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onBeforeMount, ref } from 'vue';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { defaultFormikClass } from '@/constants/formik';

import { useSalonDetail } from '@/composables/salon';
import { createInput } from '@formkit/vue';
import FormUploadImage from '@/components/form/FormUploadImage';

const displayCreateModal = ref(false);
const data = ref(null);
const uploadImage = createInput(FormUploadImage, {
  props: ['src', 'upload'],
});

const confirm = useConfirm();
const toast = useToast();

const props = defineProps({
  salonId: Number,
});

const { banners, getBanners, deleteBanner, uploadBanners } = useSalonDetail(
  props.salonId
);

const submitBanner = async () => {
  await uploadBanners(data);
  await getBanners();
  toast.add({
    severity: 'success',
    summary: 'Confirmed',
    detail: 'Banner uploaded',
    life: 3000,
  });
  displayCreateModal.value = false;
  data.value = null;
};

const showDeleteConfirmation = (index) => {
  confirm.require({
    message: 'Do you want to delete this banner?',
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger',
    accept: async () => {
      try {
        const bannerId = banners.value[index].id;
        await deleteBanner(bannerId);
        toast.add({
          severity: 'success',
          summary: 'Confirmed',
          detail: 'Banner deleted',
          life: 3000,
        });
        await getBanners();
      } catch (e) {
        toast.add({
          severity: 'error',
          summary: 'Failed Delete Banner',
          detail: 'Failed to delete banner',
          life: 3000,
        });
      }
    },
  });
};

const toggleCreateModal = () => {
  displayCreateModal.value = !displayCreateModal.value;
};

onBeforeMount(async () => {
  await getBanners();
});
</script>