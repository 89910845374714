import { ref } from "vue";

export function useMediaUtils() {
  const media = ref(null);
  const blobUrl = ref(null);
  const mediaSelected = ref(false);
  const isSubmitted = ref(false);

  const uploadImage = (newMedia) => {
    mediaSelected.value = false;
    media.value = newMedia;

    if (newMedia && newMedia.length > 0) {
      mediaSelected.value = true;
      blobUrl.value = URL.createObjectURL(newMedia[0]);
    }
  };

  const uploadCustomFileUpload = (event) => {
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      media.value = file;
      mediaSelected.value = true;
      blobUrl.value = URL.createObjectURL(file);
    }
  };
  const removeMedia = () => {
    media.value = null;
    mediaSelected.value = false;
    blobUrl.value = null;
  };

  return {
    media,
    mediaSelected,
    blobUrl,
    isSubmitted,
    uploadImage,
    uploadCustomFileUpload,
    removeMedia,
  };
}

const formatCurrency = (value) => {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  });

  return formatter.format(value);
};

export const useCommonUtils = () => {
  return {
    formatCurrency
  };
};
